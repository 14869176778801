import { Component, Mixins } from "vue-property-decorator";

import DashboardLayoutMixin from "../../common/mixins/dashboard-layout.mixin";

import AppBar from "./components/AppBar/AppBar.vue";

@Component({
  components: {
    AppBar,
  },
})
export default class MobileDashboardLayout extends Mixins(
  DashboardLayoutMixin
) {
  private showedSideBar = false;

  private toggleSideBar(showed = !this.showedSideBar) {
    this.showedSideBar = showed;
  }

  private mounted() {
    this.initFeedNewsWatcher();
    this.initMessagesSSE();
  }
}
